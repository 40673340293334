body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}

.video-grid {
  width: 100%;
  max-width: 800px;
  height: 30vh; /* Adjusted to better fit smaller screens */
  max-height: 40vh; /* Limit height for larger screens */
  margin: 10px auto;
  background: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-frame {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
  border-radius: 4px;
}

.chat-container {
  width: 100%;
  max-width: 800px;
  height: 25vh; /* Reduced for better balance on mobile */
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  overflow-y: auto; /* Add scrollbar if content overflows */
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
  gap: 8px;
  margin: 10px 0;
  padding: 8px;
  width: 100%;
}

button {
  padding: 8px 16px;
  font-size: 0.9rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease; /* Smooth hover effect */
}

button:hover {
  background: #0056b3;
}

@media (max-width: 768px) {
  .video-grid {
    height: 25vh; /* Reduce size for tablets */
  }

  .chat-container {
    height: 20vh; /* Adjust for better visibility */
  }

  button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .video-grid {
    height: 20vh; /* Further reduce size for mobile */
  }

  .chat-container {
    height: 25vh; /* Balance chat space */
  }

  .button-container {
    gap: 6px;
  }

  button {
    padding: 5px 10px;
    font-size: 0.75rem;
  }
}
